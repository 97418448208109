import React, {FC, useContext, useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {UserContext} from "Vendor/Context/UserContextProvider";
import Api from "Vendor/Api";
import { classUtils as c } from 'Vendor/Utils/ClassUtils'
import User from "Entity/User";
import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
import LoadingButton from "../../Button/LoadingButton";

const _HeaderExternal: FC = () => {
    const { currentUser, setCurrentUser, notifs, setNotifs} = useContext(UserContext)
    const [onLoad, setOnLoad] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [italic, setItalic] = useState<boolean>(false)
    const [onChange, setOnChange] = useState<string|null|undefined>(undefined)
    const [change, setChange] = useState<boolean>(false)
    const [brandModal, setBrandModal] = useState<boolean>(false)
    const [showBrandModal, setShowBrandModal] = useState<boolean>(false)
    const [showPlatformsModal, setShowPlatformsModal] = useState<boolean>(false)
    const [ notifNumber, setNotifNumber ] = useState<number>();
    const [ notifUnread, setNotifUnread ] = useState<number>(0);
    const {t} = useTranslation()


    const handleConf = () => {
        setOnLoad(true)
        Api.get('/login')
            .then(({ data }) => {
                let user = data as User
                setCurrentUser(user)
            })
            .catch((err) => {
            })
            .then(() => {
                setOnLoad(false)
            })
    }

    useEffect(() => {
        let tempName = '';

        if (currentUser?.previousInternal) {
            tempName += '#' + currentUser?.currentOrganization?.id + ' - ';
        }

        if (currentUser?.currentOrganization?.brands?.length) {
            setChange(true)
            setBrandModal(true)
            setShowBrandModal(false)
            if (currentUser.currentBrand) {
                let name:string = ''
                currentUser.currentOrganization.brands.forEach(brand => {
                    if (brand.slug === currentUser.currentBrand) {
                        name = brand.name
                    }
                })
                tempName += name
                setItalic(false)
            } else {
                tempName += (currentUser.currentOrganization && currentUser.currentOrganization.name) + ' · ' + t('app.corporate')
                setItalic(true)
            }
        } else if (currentUser?.currentOrganization) {
            setChange(false)
            tempName += currentUser.currentOrganization.name
            setItalic(false)
            setBrandModal(false)
            setShowBrandModal(false)
        } else {
            tempName += 'Error #3787'
            setItalic(true)
            setChange(false)
            setBrandModal(false)
            setShowBrandModal(false)
        }

        setName(tempName)
    }, [currentUser])

    const brandSwitch = (brand: string|null) => {
        setOnChange(brand)
        Api.get(`/switch-brand${brand !== null ? '/' + brand : ''}`)
            .then(({ data }) => {
                if (currentUser) {
                    let tempCurrentUser = {...currentUser}
                    tempCurrentUser.currentBrand = brand !== null ? brand : undefined
                    setCurrentUser(tempCurrentUser)
                }
            })
            .catch((err) => {
            })
            .then(() => {
                setOnChange(undefined)
            })
    }

    const organizationLogo = (currentUser?.currentOrganization?.logo)
    const brandLogo = (currentUser?.currentOrganization?.brands?.length && currentUser?.currentOrganization?.brands[0].logo)

    useEffect(() => {
        Api.get('/notifications/get-number')
            .then(({ data }) => {
                setNotifNumber(data.notifs);
                setNotifUnread(data.notReadNotifs);
                setNotifs(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [setNotifs]);

    /*
    useEffect(() => {
        const interval = setInterval(() => {
            Api.get('/notifications/get-number')
                .then(({ data }) => {
                    setNotifNumber(data.notifs);
                    setNotifUnread(data.notReadNotifs);
                    setNotifs(data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }, 25000);


        return () => clearInterval(interval);
    }, [setNotifs]);
   */

    useEffect(() => {
        if(notifs !== undefined) {
            setNotifNumber(notifs?.notifs)
            setNotifUnread(notifs.notReadNotifs)
        }
    }, [notifs])

    return (
        <>
            <div className="organization">
                <Link to="/">
                    {(organizationLogo !== null || brandLogo !== null) &&
                    <div className="icon">
                        <div
                            className="logo"
                            style={{
                                backgroundImage: "url('"+ `${brandLogo ? brandLogo : organizationLogo}` + "')",
                            }}
                        />
                    </div>
                    }
                </Link>
                <div className="data">
                    {change ? <a className="name" href="#" onClick={(e) => {
                        e.stopPropagation()
                        setShowBrandModal(true)
                    }}>{italic ? <i>{name}</i> : name}</a> : <span className="name">{italic ? <i>{name}</i> : name}</span>}
                    <div className="links">
                        <Link to='#' onClick={handleConf} className="settings">
                            <i className={c('fa-solid fa-gear', onLoad && 'fa-spin')}></i>
                        </Link>
                        <Link className="text-danger" to="/notifications">
                            <span className=
                                      { notifNumber === 0 ?
                                          "rounded-pill text-white p-1"
                                          : (notifUnread > 0 ?
                                          "bg-danger rounded-pill text-dark p-1"
                                          : "bg-warning rounded-pill text-dark p-1")}>
                                { notifNumber }
                            </span>
                        </Link>
                        {currentUser?.currentOrganization?.platforms?.length &&
                            <>
                            {currentUser.currentOrganization.platforms.length === 1 && !currentUser.group?.restrictedAccess ? <a href={currentUser.currentOrganization.platforms[0].url} target="_blank" rel="noreferrer">{t('app.platform_access', {count: 1})}</a>
                                : <a href="#" onClick={(e) => {
                                    e.stopPropagation()
                                    setShowPlatformsModal(true)
                                }}>{t('app.platform_access', {count: currentUser.currentOrganization.platforms.length})}</a>
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
            {brandModal && <Modal show={showBrandModal} onHide={() => {setShowBrandModal(false)}}>
                <Modal.Header>
                    <Modal.Title>{t('app.modal.choose_brand.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="choose-brand">
                        <li key={0} className={currentUser && currentUser.currentBrand === undefined ? 'active' : ''}>
                            <LoadingButton variant="light" isLoading={onChange === null} onClick={() => {
                                brandSwitch(null)
                            }}>Corporate</LoadingButton>
                        </li>
                        {currentUser?.currentOrganization?.brands?.map((brand, i) => (
                            <li key={i + 1} className={currentUser.currentBrand === brand.slug ? 'active' : ''}><LoadingButton variant="light" disabled={onChange !== undefined && onChange !== brand.slug} isLoading={onChange === brand.slug} onClick={() => {
                                brandSwitch(brand.slug)
                            }}>{brand.name}</LoadingButton></li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>}
            {(currentUser?.currentOrganization?.platforms?.length ?? 0) > 1 && <Modal show={showPlatformsModal} onHide={() => {setShowPlatformsModal(false)}}>
                <Modal.Header>
                    <Modal.Title>{t('app.modal.choose_platform.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="choose-platform">
                        {currentUser?.currentOrganization?.platforms?.map((platform, i) => (
                            <li key={i}><a href={platform.url} target="_blank" rel="noreferrer" className="btn btn-primary"><i className="fa-solid fa-external-link me-2"></i>{platform.name}</a></li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>}
        </>
    )
}

export default _HeaderExternal
